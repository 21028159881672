import styled, { css } from 'styled-components'

import { colors } from '../../constants/colors'
import { zIndex } from '../../constants/zIndex'
import { PartialBookingEngine } from '../../styles/types'
import { media } from '../../utils/media'

export const SearchContainer = styled.div`
  width: 100%;
  position: relative;
  z-index: ${zIndex.above};
  margin-top: ${({ theme }) => theme.spacing[1.5]};
`

export const InputContainer = styled.div<{
  themeStyle?: PartialBookingEngine['transportationFlow']['locationPicker']['locationSearch']['searchInput']
}>`
  background: ${colors.white};
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
  width: 100%;
  position: relative;

  ::after {
    background: ${props =>
      props?.themeStyle?.frameBackground ??
      'linear-gradient(90deg, #6793ae 0%, #50a7ba 97.99%)'};
    bottom: ${({ theme }) => theme.spacing[-1.5]};
    content: '';
    left: ${({ theme }) => theme.spacing[-1.5]};
    opacity: 0.25;
    position: absolute;
    right: ${({ theme }) => theme.spacing[-1.5]};
    top: ${({ theme }) => theme.spacing[-1.5]};
    z-index: -1;
    border-radius: ${({ theme }) =>
      theme?.borderRadius?.elements ? theme.borderRadius.elements + 'px' : '0'};
  }
`

export const Input = styled.input<{
  themeStyle?: PartialBookingEngine['transportationFlow']['locationPicker']['locationSearch']['searchInput']['input']
}>`
  font-size: 16px;
  font-weight: 500;
  height: 100%;
  padding: ${({ theme }) => `${theme.spacing[1.5]} ${theme.spacing[2]}`};
  width: 100%;

  background-color: ${props => props?.themeStyle?.backgroundColor};
  color: ${props => props?.themeStyle?.textColor};

  ::placeholder {
    color: ${props => props.themeStyle?.placeholderColor ?? colors.grey};
  }

  ${media.md(css`
    font-size: ${({ theme }) => theme.spacing[1]};
  `)}
`

export const SearchIcon = styled.div`
  height: ${({ theme }) => theme.spacing[1]};
  min-height: 16px;
  min-width: 16px;
  position: absolute;
  right: ${({ theme }) => theme.spacing[1.5]};
  top: 50%;
  transform: translateY(-50%);
  width: ${({ theme }) => theme.spacing[1]};
`

export const Dropdown = styled.ul<{
  themeStyle?: PartialBookingEngine['transportationFlow']['locationPicker']['locationSearch']['searchInput']['dropDown']
}>`
  background: ${props => props?.themeStyle?.backgroundColor ?? colors.white};
  box-shadow: ${props =>
    props?.themeStyle?.boxShadow ?? '0px 8px 24px rgba(80, 167, 186, 0.12)'};
  max-height: 300px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1;
`

export const ListItem = styled.li<{
  isHighlightedIndex?: boolean
  isSelectedItem?: boolean
  themeStyle?: PartialBookingEngine['transportationFlow']['locationPicker']['locationSearch']['searchInput']['dropDown']
}>`
  padding: ${({ theme }) => `${theme.spacing[1]}} ${theme.spacing[2]}`};

  background-color: ${({ isHighlightedIndex, themeStyle }) =>
    isHighlightedIndex
      ? colors.deepBlue
      : themeStyle?.backgroundColor ?? colors.white};

  color: ${({ isHighlightedIndex, isSelectedItem }) =>
    isHighlightedIndex || isSelectedItem ? colors.white : 'inherit'};
`
