import {
  FlowAccordionField,
  FlowAddProductCardField,
  FlowAvailableDateTimesField,
  FlowBreadcrumbField,
  FlowButtonField,
  FlowCalendarField,
  FlowCardButtonField,
  FlowChangeDateField,
  FlowCheckboxField,
  FlowConfirmationSummaryField,
  FlowCustomField,
  FlowDatePickerField,
  FlowDateTimeSelectField,
  FlowDisclaimerField,
  FlowDiscountAccordionField,
  FlowDrawerField,
  FlowImageField,
  FlowInputTextField,
  FlowItineraryField,
  FlowModalField,
  FlowMultipleFields,
  FlowOverviewField,
  FlowPhoneInputField,
  FlowPickerField,
  FlowProductCardField,
  FlowProgressButtonField,
  FlowRichTextField,
  FlowScreenErrorField,
  FlowSelectDayVisitTimeField,
  FlowSelectField,
  FlowSpaPackageTableField,
  FlowTextAreaField,
  FlowTextField,
} from '../types'

type FieldBuilderType<T> = (props: Omit<T, 'type' | 'subType' | 'role'>) => T

export const buildText: FieldBuilderType<FlowTextField> = props => ({
  ...props,
  type: 'field',
  role: 'text',
  subType: 'text',
})

export const buildRichText: FieldBuilderType<FlowRichTextField> = props => ({
  ...props,
  type: 'field',
  role: 'text',
  subType: 'richtext',
})

export const buildInputText: FieldBuilderType<FlowInputTextField> = props => ({
  ...props,
  type: 'field',
  role: 'input',
  subType: 'inputText',
})

export const buildButton: FieldBuilderType<FlowButtonField> = props => ({
  ...props,
  type: 'field',
  role: 'button',
  subType: 'button',
})

export const buildProgressButton: FieldBuilderType<FlowProgressButtonField> =
  props => ({
    ...props,
    type: 'field',
    role: 'button',
    subType: 'progressButton',
  })

export const buildPickerField: FieldBuilderType<FlowPickerField> = props => ({
  ...props,
  type: 'field',
  role: 'input',
  subType: 'picker',
})

export const buildCalendarField: FieldBuilderType<FlowCalendarField> =
  props => ({
    ...props,
    type: 'field',
    role: 'input',
    subType: 'calendar',
  })

export const buildSpaPackageTableField: FieldBuilderType<
  FlowSpaPackageTableField<unknown>
> = props => ({
  ...props,
  type: 'field',
  role: 'input',
  subType: 'spaPackage',
})

export const buildCardButtonField: FieldBuilderType<FlowCardButtonField> =
  props => ({
    ...props,
    type: 'field',
    role: 'button',
    subType: 'cardButton',
  })

export const buildProductCardField: FieldBuilderType<FlowProductCardField> =
  props => ({
    ...props,
    type: 'field',
    role: 'button',
    subType: 'productCard',
  })

export const buildBreadcrumbField: FieldBuilderType<FlowBreadcrumbField> =
  props => ({
    ...props,
    type: 'field',
    role: 'button',
    subType: 'breadcrumb',
  })

export const buildModalField: FieldBuilderType<FlowModalField> = props => ({
  ...props,
  type: 'field',
  role: 'modal',
  subType: 'modal',
})
export const buildDrawerField: FieldBuilderType<FlowDrawerField> = props => ({
  ...props,
  type: 'field',
  role: 'modal',
  subType: 'drawer',
})

export const buildCustomField: FieldBuilderType<FlowCustomField> = props => ({
  ...props,
  type: 'field',
  role: 'custom',
  subType: 'custom',
})

export const buildMultipleFields: FieldBuilderType<FlowMultipleFields> =
  props => ({
    ...props,
    type: 'field',
    role: 'custom',
    subType: 'multiple',
  })

export const buildScreenErrorField: FieldBuilderType<FlowScreenErrorField> =
  props => ({
    ...props,
    type: 'field',
    role: 'error',
    subType: 'screenError',
  })

export const buildTextAreaField: FieldBuilderType<FlowTextAreaField> =
  props => ({
    ...props,
    type: 'field',
    role: 'input',
    subType: 'textarea',
  })

export const buildDiscountAccordionField: FieldBuilderType<FlowDiscountAccordionField> =
  props => ({
    ...props,
    type: 'field',
    role: 'input',
    subType: 'discountAccordion',
  })

export const buildDatePickerField: FieldBuilderType<FlowDatePickerField> =
  props => ({
    ...props,
    type: 'field',
    role: 'input',
    subType: 'datepicker',
  })

export const buildSelectField: FieldBuilderType<FlowSelectField> = props => ({
  ...props,
  type: 'field',
  role: 'input',
  subType: 'select',
})

export const buildImageField: FieldBuilderType<FlowImageField> = props => ({
  ...props,
  type: 'field',
  role: 'image',
  subType: 'image',
})

export const buildPhoneInputField: FieldBuilderType<FlowPhoneInputField> =
  props => ({
    ...props,
    type: 'field',
    role: 'input',
    subType: 'inputPhone',
  })

export const buildCheckboxInputField: FieldBuilderType<FlowCheckboxField> =
  props => ({
    ...props,
    type: 'field',
    role: 'input',
    subType: 'checkbox',
  })

export const buildItineraryField: FieldBuilderType<FlowItineraryField> =
  props => ({
    ...props,
    type: 'field',
    role: 'itinerary',
    subType: 'itinerary',
  })

export const buildDateTimeSelectField: FieldBuilderType<FlowDateTimeSelectField> =
  props => ({
    ...props,
    type: 'field',
    role: 'input',
    subType: 'dateTimeSelect',
  })

/**
 * @deprecated This component deprecated because it fetches data from old API.
 */
export const buildAvailableDateTimesField: FieldBuilderType<FlowAvailableDateTimesField> =
  props => ({
    ...props,
    type: 'field',
    role: 'custom',
    subType: 'availableDateTimes',
  })

export const buildAccordionField: FieldBuilderType<FlowAccordionField> =
  props => ({
    ...props,
    type: 'field',
    role: 'custom',
    subType: 'accordion',
  })

export const buildAddProductCardField: FieldBuilderType<FlowAddProductCardField> =
  props => ({
    ...props,
    type: 'field',
    role: 'custom',
    subType: 'addProductCard',
  })

export const buildSelectDayVisitTimeField: FieldBuilderType<FlowSelectDayVisitTimeField> =
  props => ({
    ...props,
    type: 'field',
    role: 'custom',
    subType: 'selectDayVisitTime',
  })

export const buildChangeDateField: FieldBuilderType<FlowChangeDateField> =
  props => ({
    ...props,
    type: 'field',
    role: 'custom',
    subType: 'changeDate',
  })

export const buildDisclaimerField: FieldBuilderType<FlowDisclaimerField> =
  props => ({
    ...props,
    type: 'field',
    role: 'disclaimer',
    subType: 'disclaimer',
  })

export const buildOverviewField: FieldBuilderType<FlowOverviewField> =
  props => ({
    ...props,
    type: 'field',
    role: 'custom',
    subType: 'overview',
  })

export const buildConfirmationSummaryField: FieldBuilderType<FlowConfirmationSummaryField> =
  props => ({
    ...props,
    type: 'field',
    role: 'custom',
    subType: 'confirmationSummary',
  })
