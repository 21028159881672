import { defineMessages } from 'react-intl'

const text = defineMessages({
  timeSlotsFull: {
    id: 'sharedFlows:timeSlots.full',
    defaultMessage: 'We are sorry, everything seems to be fully booked.',
    description: 'Global - timeslots: Message when no timeslots are available',
  },
  timeSlotsFullyBooked: {
    id: 'sharedFlows:timeSlots.fullyBooked',
    defaultMessage: 'Fully booked',
    description:
      'Global - timeslots: Text that indicates a certain timeslot is fully booked',
  },
  timeSlotsAvailableSlots: {
    id: 'sharedFlows:timeSlots.availableSlots',
    defaultMessage: '{time}: {available} openings left',
    description:
      'Global - timeslots: Text that shows openings left for a certain timeslot -> HH:MM: X openings left',
  },
  timeSlotsOtherOptions: {
    id: 'sharedFlows:timeSlots.otherOptions',
    defaultMessage: 'Other available',
    description:
      'Global - timeslots: Other available label in SelectDayVisitTimeField',
  },
  timeSlotsSelect: {
    id: 'sharedFlows:timeSlots.select',
    defaultMessage: 'Select',
    description: 'Global - timeslots: Select label',
  },
  timeSlotsTimeLabel: {
    id: 'sharedFlows:timeSlots.timeLabel',
    defaultMessage: 'Time',
    description:
      'Global - timeslots: Label for time in SelectDayVisitTimeField and AvailableTimeSlots',
  },
  timeSlotsPriceLabel: {
    id: 'sharedFlows:timeSlots.priceLabel',
    defaultMessage: 'Price',
    description:
      'Global - timeslots: Label for price in SelectDayVisitTimeField',
  },
  timeSlotsPriceRetreatLabel: {
    id: 'sharedFlows:timeSlots.timeSlotsPriceRetreatLabel',
    defaultMessage: 'Price',
    description:
      'Global - timeslots: Label for price in SelectDayVisitTimeField for Retreat Spa',
  },
  timeSlotsShowTable: {
    id: 'sharedFlows:timeSlots.showTable',
    defaultMessage: 'Show timetable',
    description: 'Global - timeslots: Show timetable label',
  },
  timeSlotsHideTable: {
    id: 'sharedFlows:timeSlots.hideTable',
    defaultMessage: 'Hide timetable',
    description: 'Global - timeslots: Hide timetable label',
  },
  timeSlotsAvailabilityLabel: {
    id: 'sharedFlows:timeSlots.availabilityLabel',
    defaultMessage: 'Availability',
    description: 'Global - timeslots: Availability label',
  },
  timeSlotsAvailableLabel: {
    id: 'sharedFlows:timeSlots.availableLabel',
    defaultMessage: 'Available',
    description: 'Global - timeslots: Available label',
  },
  timeSlotsAvailableSlotsLeft: {
    id: 'sharedFlows:timeSlots.availableSlotsLeft',
    defaultMessage: '{available} openings left',
    description:
      'Global - timeslots: Text that show openings left -> X openings left',
  },
  timeMinutes: {
    id: 'sharedFlows:timeMinutes',
    defaultMessage: '{minutes} minutes',
    description: 'Global: X minutes label',
  },
  timeHours: {
    id: 'sharedFlows:timeHours',
    defaultMessage: '{hours} hours',
    description: 'Global: X hours label',
  },
  pickupLocationTitle: {
    id: 'sharedFlows:busLocationPicker.pickupLocationTitle',
    defaultMessage: 'Select pickup location',
    description: 'Global - location picker: Location picker pickup title',
  },
  dropoffLocationTitle: {
    id: 'sharedFlows:busLocationPicker.dropoffLocationTitle',
    defaultMessage: 'Select dropoff location',
    description: 'Global - location picker: Location picker dropoff title',
  },
  pickupDropoffSubtitle: {
    id: 'sharedFlows:busLocationPicker.pickupDropoffSubtitle',
    defaultMessage:
      'Search for your accommodation or choose from the location list.',
    description:
      'Global - location picker: Location picker subtitle for pickup and dropoff',
  },
  pickupDropoffEditSubtitle: {
    id: 'sharedFlows:busLocationPicker.pickupDropoffEditSubtitle',
    defaultMessage: 'Your previous {direction} location was {location}',
    description:
      'Global - location picker: Location picker subtitle for pickup and dropoff',
  },
  pickupListTitle: {
    id: 'sharedFlows:busLocationPicker.pickupListTitle',
    defaultMessage: 'Popular pickup locations',
    description: 'Global - location picker: Title of pickup location list',
  },
  dropoffListTitle: {
    id: 'sharedFlows:busLocationPicker.dropoffListTitle',
    defaultMessage: 'Popular dropoff locations',
    description: 'Global - location picker: Title of dropoff location list',
  },
  pickupDropoffInputPlaceholder: {
    id: 'sharedFlows:busLocationPicker.pickupDropoffInputPlaceholder',
    defaultMessage: 'Search for your hotel',
    description:
      'Global - location picker: Placeholder for location search input',
  },
  pickupConfirm: {
    id: 'sharedFlows:busLocationPicker.pickupConfirm',
    defaultMessage: 'Confirm pickup',
    description: 'Global - location picker: Label for confirm pickup button',
  },
  pleaseWaitAt: {
    id: 'sharedFlows:busLocationPicker.pleaseWaitAt',
    defaultMessage: 'Please wait at {location}',
    description:
      'Global - location picker: Label for please wait at for bus stop',
  },
  dropoffConfirm: {
    id: 'sharedFlows:busLocationPicker.dropoffConfirm',
    defaultMessage: 'Confirm dropoff',
    description: 'Global - location picker: Label for confirm dropoff button',
  },
  pickupDisclaimer: {
    id: 'sharedFlows:busLocationPicker.pickupDisclaimer',
    defaultMessage: 'Pickup 30 minutes prior',
    description:
      'Global - location picker: Disclaimer for pickup locations that pickup is 30 minutes prior',
  },
  cartTitle: {
    id: 'sharedFlows:cart.title',
    defaultMessage: 'Your selection',
    description: 'Gobal - cart: Cart title',
  },
  hotelCartSubtitle: {
    id: 'sharedFlows:hotel.cart.subtitle',
    defaultMessage:
      'Your dates: {arrivalDate} - {departureDate} ({nights} {nights, plural, one {night} other {nights}})',
    description: 'Gobal - cart: Cart subtitle',
  },
  hotelCartSubtitleNoFormat: {
    id: 'sharedFlows:hotel.cart.subtitleNoFormat',
    defaultMessage: 'Your dates:',
    description: 'Gobal - cart: Cart subtitle',
  },
  hotelRoomIndex: {
    id: 'sharedFlows:hotel.roomIndex',
    defaultMessage: 'Room {index}',
    description: 'Global - rooms: Room index',
  },
  editCart: {
    id: 'sharedFlows:cart.editCart',
    defaultMessage: 'Edit',
    description: 'Gobal - cart: Edit cart',
  },
  removeFromCart: {
    id: 'sharedFlows:cart.removeFromCart',
    defaultMessage: 'Remove',
    description: 'Gobal - cart: Remove from cart',
  },
  cartIskDisclaimer: {
    id: 'sharedFlows:cart.iskDisclaimer',
    defaultMessage:
      'You will be charged {amount} (Icelandic currency) {numberOfDays} days prior to your arrival.',
    description: 'Global - cart: Cart ISK disclaimer',
  },
  cartIskDisclaimerHighland: {
    id: 'sharedFlows:cart.cartIskDisclaimerHighland',
    defaultMessage: 'You will be charged {amount} (Icelandic currency).',
    description: 'Global - cart: Cart ISK disclaimer',
  },
  spaCartIskDisclaimer: {
    id: 'sharedFlows:cart.spaCartIskDisclaimer',
    defaultMessage: 'You will be charged {amount} (Icelandic currency)',
    description: 'Global - cart: Spa Cart ISK disclaimer',
  },
  spaCartMCPDisclaimer: {
    id: 'sharedFlows:cart.spaCartMCPDisclaimer',
    defaultMessage:
      'You will be charged in {currency} if supported for this transaction. The total amount is what you will be charged including currency conversion fees.',
    description: 'Global - cart: Cart MCP disclaimer',
  },
  MCPNotSupported: {
    id: 'sharedFlows:bookingDetails.payment.MCPNotSupported',
    defaultMessage:
      "Your card doesn't support charges in {currency} for this transaction. The charge will be made as {iskAmount}",
    description: 'Global - cart: Cart MCP disclaimer',
  },
  sessionTimeoutTitle: {
    id: 'sharedFlows:cart.sessionTimeoutTitle',
    defaultMessage: 'Booking expired',
    description:
      'Global - cart: Title of modal shown to user when cart session has timed out',
  },
  sessionTimeoutSubtitle: {
    id: 'sharedFlows:cart.sessionTimeoutSubtitle',
    defaultMessage: 'Your booking will be reset',
    description:
      'Global - cart: Subtitle of modal shown to user when cart session has timed out',
  },
  sessionTimeoutWarningTitle: {
    id: 'sharedFlows:cart.sessionTimeoutWarningTitle',
    defaultMessage: 'Are you still booking?',
    description:
      'Global - cart: Title of modal shown to user when cart session is about to time out in 2 minutes',
  },
  sessionTimeoutWarningSubtitle: {
    id: 'sharedFlows:cart.sessionTimeoutWarningSubtitle',
    defaultMessage:
      'Your booking will expire within the next few minutes if you do not continue',
    description:
      'Global - cart: Subtitle of modal shown to user when cart session is about to time out in 2 minutes',
  },
  resetCartPromptTitle: {
    id: 'sharedFlows:cart.resetCartPromptTitle',
    defaultMessage: 'Reset booking?',
    description:
      'Global - cart: Title of modal shown to user when the next action requires the booking to be reset',
  },
  resetCartPromptSubtitle: {
    id: 'sharedFlows:cart.resetCartPromptSubtitle',
    defaultMessage: 'Do you want to to reset your booking?',
    description:
      'Global - cart: Subtitle of modal shown to user when given the chance not to reset booking by their next action',
  },
  emptyCartTitle: {
    id: 'sharedFlows:cart.emptyCartTitle',
    defaultMessage: 'Empty booking',
    description:
      'Global - cart: Title of modal shown to user when booking is empty',
  },
  emptyCartSubtitle: {
    id: 'sharedFlows:cart.emptyCartSubtitle',
    defaultMessage: 'Your booking is empty, restarting booking flow',
    description:
      'Global - cart: Subtitle of modal shown to user when booking is empty',
  },
  overviewTitle: {
    id: 'sharedFlows:overview.overviewTitle',
    defaultMessage: 'Overview',
    description:
      'Global - overview: Title on overview screen - used in multiple subflows',
  },
  overviewUpgrade: {
    id: 'sharedFlows:overview.overviewUpgrade',
    defaultMessage: 'Upgrade to {package}',
    description: 'Global - overview: Upgrade package label',
  },
  overviewEditSubtitle: {
    id: 'sharedFlows:overview.overviewEditSubtitle',
    defaultMessage: 'Please make sure these are the changes you want to make',
    description:
      'Global - overview: Subtitle on overview screen when editing - used in multiple subflows',
  },
  confirmationEditTitle: {
    id: 'sharedFlows:confirmation.confirmationEditTitle',
    defaultMessage: 'Booking Updated',
    description:
      'Gobal: Title on confirmation screen when editing - used in multiple subflows',
  },
  adults: {
    id: 'sharedFlows:global.adults',
    defaultMessage: '{adults} {adults, plural, one {adult} other {adults}}',
    description: 'Global: Adults count',
  },
  children: {
    id: 'sharedFlows:global.children',
    defaultMessage:
      '{children} {children, plural, one {child} other {children}}',
    description: 'Global: Children count',
  },
  youngAdults: {
    id: 'sharedFlows:global.youngAdults',
    defaultMessage:
      '{youngAdults} {youngAdults, plural, one {young adult} other {young adults}}',
    description: 'Global: Young adults count',
  },
  infants: {
    id: 'sharedFlows:global.infants',
    defaultMessage: '{infants} {infants, plural, one {infant} other {infants}}',
    description: 'Global: Infants count',
  },
  guests: {
    id: 'sharedFlows:global.guests',
    defaultMessage: '{guests} {guests, plural, one {guest} other {guests}}',
    description: 'Global: Guests count',
  },
  nights: {
    id: 'sharedFlows:global.nights',
    defaultMessage: '{nights} {nights, plural, one {night} other {nights}}',
    description: 'Global: Nights count',
  },
  free: {
    id: 'sharedFlows:global.free',
    defaultMessage: 'Free',
    description: 'Global: Free',
  },
  reserved: {
    id: 'sharedFlows:global.reserved',
    defaultMessage: 'Reserved',
    description: 'Global: Reserved',
  },
  notAvailable: {
    id: 'sharedFlows:global.notAvailable',
    defaultMessage: 'Not available',
    description: 'Global: Not available',
  },
  pending: {
    id: 'sharedFlows:global.pending',
    defaultMessage: 'Pending',
    description: 'Global: Pending',
  },
  forGuests: {
    id: 'sharedFlows:global.forGuests',
    defaultMessage: 'for {count, plural, one {# guest} other {# guests}}',
    description: 'Global: For # guest/s',
  },
  dateAtTime: {
    id: 'sharedFlows:label.dateAtTime',
    defaultMessage: '{date} at {time}',
    description:
      'Global: example: "10 Oct 23 at 04:30", date and time can be formatted for different uses ',
  },
  transferFromRvkToHb: {
    id: 'sharedFlows:label.transferFromRvkToHb',
    defaultMessage: 'Transfer from Reykjavik to Highland Base',
    description: 'Global: Transfer from Reykjavik to Highland Base',
  },
  transferFromHbToRvk: {
    id: 'sharedFlows:label.transferFromHbToRvk',
    defaultMessage: 'Transfer from Highland Base to Reykjavik',
    description: 'Global: Transfer from Highland Base to Reykjavik',
  },
  transferFromSkjolToHb: {
    id: 'sharedFlows:label.transferFromSkjolToHb',
    defaultMessage: 'Transfer from Skjól to Highland Base',
    description: 'Global: Transfer from Skjól to Highland Base',
  },
  transferFromHbToSkjol: {
    id: 'sharedFlows:label.transferFromHbToSkjol',
    defaultMessage: 'Transfer from Highland Base to Skjól',
    description: 'Global: Transfer from Highland Base to Skjól',
  },
  privateTransferSmallForGuests: {
    id: 'sharedFlows:label.privateTransferSmallForGuests',
    defaultMessage: 'for 1-3 guests',
    description:
      'Global: Text for number of guests in private transfer - used on confirmation screens',
  },
  privateTransferLargeForGuests: {
    id: 'sharedFlows:label.privateTransferLargeForGuests',
    defaultMessage: 'for 4-8 guests',
    description:
      'Global: Text for number of guests in private transfer - used on confirmation screens',
  },
  privateTransferSmallServioForGuests: {
    id: 'sharedFlows:label.privateTransferSmallServioForGuests',
    defaultMessage: 'for 1-2 guests',
    description:
      'Global: Text for number of guests in private transfer small Servio - used on confirmation screens',
  },
  privateTransferMediumServioForGuests: {
    id: 'sharedFlows:label.privateTransferMediumServioForGuests',
    defaultMessage: 'for 1-4 guests',
    description:
      'Global: Text for number of guests in private transfer medium Servio - used on confirmation screens',
  },
  privateTransferLargeServioForGuests: {
    id: 'sharedFlows:label.privateTransferLargeServioForGuests',
    defaultMessage: 'for 1-9 guests',
    description:
      'Global: Text for number of guests in private transfer large Servio - used on confirmation screens',
  },
  yourBluelagoonBooking: {
    id: 'sharedFlows:text.yourBluelagoonBooking',
    defaultMessage: 'Your Bluelagoon booking',
    description: 'Global: Text for Your Bluelagoon booking - used in itinerary',
  },
  yourRetreatBooking: {
    id: 'sharedFlows:text.yourRetreatBooking',
    defaultMessage: 'Your Retreat Spa booking',
    description:
      'Global: Text for Your Retreat Spa booking - used in itinerary',
  },
  yourHighlandBaseBathsBooking: {
    id: 'sharedFlows:text.yourHighlandBaseBathsBooking',
    defaultMessage: 'Your Highland Base Baths booking',
    description:
      'Global: Text for Your Highland Base Baths booking - used in itinerary',
  },
  timeSpentHere: {
    id: 'sharedFlows:text.timeSpentHere',
    defaultMessage:
      'People usually spend {hours} {hours, plural, one {hour} other {hours}} here',
    description:
      'Global: Text for time spent here in minutes - used in itinerary',
  },
  membershipLoginTitle: {
    id: 'sharedFlows:text.membershipLoginTitle',
    defaultMessage: 'Book with a subscription card',
    description: 'Global: Text for phone number when signing in',
  },
  enterConfirmationNumber: {
    id: 'sharedFlows:text.enterConfirmationNumber',
    defaultMessage: 'Enter confirmation number',
    description: 'Global: Text for confirmation number',
  },
  bookWithSubscriptionCard: {
    id: 'sharedFlows:text.bookWithSubscriptionCard',
    defaultMessage: 'Book with a subscription card',
    description: 'Global - Text for book with subscription card',
  },
  bookWithWinterCardSubtitle: {
    id: 'sharedFlows:text.bookWithWinterCardSubtitle',
    defaultMessage:
      'Keep it regularly cozy with us this winter with the Blue Lagoon winter card.',
    description: 'Global - Text for book with subscription card',
  },
  bookWithSummerCardSubtitle: {
    id: 'sharedFlows:text.bookWithSummerCardSubtitle',
    defaultMessage:
      'Keep it regularly cozy with us this summer with the Blue Lagoon summer card.',
    description: 'Global - Text for book with subscription card',
  },
  winterCardAccepted: {
    id: 'sharedFlows:text.winterCardAccepted',
    defaultMessage: 'Winter card accepted',
    description: 'Global - Text for book with subscription card',
  },
  summerCardAccepted: {
    id: 'sharedFlows:text.summerCardAccepted',
    defaultMessage: 'Summer card accepted',
    description: 'Global - Text for book with subscription card',
  },
  validUntil: {
    id: 'sharedFlows:text.validUntil',
    defaultMessage: 'Valid until {date}',
    description: 'Global - Text for subscription valid until date',
  },
  weRecommend: {
    id: 'sharedFlows:text.weRecommend',
    defaultMessage: 'We recommend',
    description: 'Global - Text for we recommend on top of admission card',
  },
  paymentDetailsTitle: {
    id: 'sharedFlows:text.paymentDetailsTitle',
    defaultMessage: 'Payment Details',
    description: 'Gobal: Title on payment details screen',
  },
  paymentDetailsSubtitle: {
    id: 'sharedFlows:text.paymentDetailsSubtitle',
    defaultMessage: 'Your total: {amount}',
    description: 'Gobal: Subtitle on payment details screen',
  },
  chargedDynamicDaysBeforeArrival: {
    id: 'sharedFlows:text.chargedDynamicDaysBeforeArrival',
    defaultMessage:
      'You will be charged {daysAmount} days before your arrival.',
    description:
      'Global: Information about that the customer will be charged x days before arrival',
  },
  paymentStatus: {
    id: 'sharedFlows:text.paymentStatus',
    defaultMessage: 'Processing payment',
    description: 'Global: Text for when payment is processing',
  },
  orderStatus: {
    id: 'sharedFlows:text.orderStatus',
    defaultMessage: 'Securing tickets',
    description: 'Global: Text for when order is being created',
  },
  confirmStatus: {
    id: 'sharedFlows:text.confirmStatus',
    defaultMessage: 'Finishing touches',
    description: 'Global: Text for when order is being confirmed',
  },
  pricePerGuest: {
    id: 'sharedFlows:text.pricePerGuest',
    defaultMessage: '{price} per guest',
    description: 'Global: Price per guest, e.g. 1 ISK per guest',
  },
})

const buttons = defineMessages({
  continue: {
    id: 'sharedFlows:button.continue',
    defaultMessage: 'Continue',
    description: 'Global - buttons: Continue button',
  },
  retry: {
    id: 'sharedFlows:button.retry',
    defaultMessage: 'Retry',
    description: 'Global - buttons: Retry button',
  },
  cancel: {
    id: 'sharedFlows:button.cancel',
    defaultMessage: 'Cancel',
    description: 'Global - buttons: Cancel button',
  },
  select: {
    id: 'sharedFlows:button.select',
    defaultMessage: 'Select',
    description: 'Global - buttons: Select button',
  },
  closeWindow: {
    id: 'sharedFlows:button.closeWindow',
    defaultMessage: 'Close Window',
    description: 'Global - buttons: Close window button',
  },
  goToBlueLagoon: {
    id: 'sharedFlows:button.goToBlueLagoon',
    defaultMessage: 'Go to bluelagoon.com',
    description: 'Global - buttons: Go to bluelagoon.com(closes flow)',
  },
  agreeAndContinue: {
    id: 'sharedFlows:button.agreeAndContinue',
    defaultMessage: 'Continue to payment',
    description: 'Global - buttons: Agree and continue button',
  },
  agreeAndConfirmUpdate: {
    id: 'sharedFlows:button.agreeAndConfirmUpdate',
    defaultMessage: 'Agree and confirm update',
    description: 'Global - buttons: Agree and confirm update button',
  },
  resetCart: {
    id: 'sharedFlows:button.resetCart',
    defaultMessage: 'Yes, start over',
    description: 'Global - buttons: Reset cart button',
  },
  continueWithBooking: {
    id: 'sharedFlows:button.continueWithBooking',
    defaultMessage: 'No, continue with booking',
    description: 'Global - buttons: Continue with booking button',
  },
  reloadCart: {
    id: 'sharedFlows:button.reloadCart',
    defaultMessage: 'Reload cart',
    description: 'Global - buttons: Reload cart button',
  },
  resetBooking: {
    id: 'sharedFlows:button.resetBooking',
    defaultMessage: 'Reset booking',
    description: 'Global - buttons: Reset booking button',
  },
  timeRefetch: {
    id: 'sharedFlows:button.timeRefetch',
    defaultMessage: 'Refetch',
    description: 'Global - buttons: Refetch button',
  },
  details: {
    id: 'sharedFlows:button.details',
    defaultMessage: 'Details',
    description: 'Global - buttons: Details button - used in multiple places',
  },
  addRow: {
    id: 'sharedFlows:button.addRow',
    defaultMessage: 'Add row',
    description: 'Global - buttons: Add row button - used for promo code',
  },
  add: {
    id: 'sharedFlows:button.add',
    defaultMessage: 'Add',
    description: 'Global - buttons: Add button - used for promo code',
  },
  remove: {
    id: 'sharedFlows:button.remove',
    defaultMessage: 'Remove',
    description: 'Global - buttons: Remove button - used for promo code',
  },
  membershipLogin: {
    id: 'sharedFlows:button.membershipLogin',
    defaultMessage: 'Login',
    description: 'Global - buttons: Membership login button',
  },
  confirm: {
    id: 'sharedFlows:button.confirm',
    defaultMessage: 'Confirm',
    description: 'Global - buttons: Confirm',
  },
  sendAgain: {
    id: 'sharedFlows:button.sendAgain',
    defaultMessage: 'Send again',
    description: 'Global - buttons: Send again, e.g. code for membership login',
  },
  changeNumber: {
    id: 'sharedFlows:button.changeNumber',
    defaultMessage: 'Use another number',
    description: 'Global - buttons: Use another number during membership login',
  },
  bookWithWinterCard: {
    id: 'sharedFlows:button.bookWithWinterCard',
    defaultMessage: 'Book with winter card',
    description: 'Global - buttons: book with winter card',
  },
  bookWithSummerCard: {
    id: 'sharedFlows:button.bookWithSummerCard',
    defaultMessage: 'Book with summer card',
    description: 'Global - buttons: book with summer card',
  },
  checkAvailability: {
    id: 'sharedFlows:button.checkAvailability',
    defaultMessage: 'Check availability',
    description: 'Global - buttons: check availability',
  },
  summerCardDetailsLink: {
    id: 'sharedFlows:button.summerCardDetailsLink',
    defaultMessage: '/is/sumarkort',
    description: 'Global - buttons: link for summer card details',
  },
  winterCardDetailsLink: {
    id: 'sharedFlows:button.winterCardDetailsLink',
    defaultMessage: '/is/vetrarkort',
    description: 'Global - buttons: link for winter card details',
  },
  loginToMyBluelagoon: {
    id: 'sharedFlows:button.loginToMyBluelagoon',
    defaultMessage: 'Login to My Bluelagoon',
    description: 'Global - buttons: link for winter card details',
  },
  continueToPaymentButton: {
    id: 'sharedFlows:button.continueToPaymentButton',
    defaultMessage: 'Continue to payment',
    description: 'Global - buttons: Continue to payment button',
  },
  plusItems: {
    id: 'sharedFlows:button.plusItems',
    defaultMessage: '+{count} {count, plural, one {Item} other {Items}}',
    description: 'Global - buttons: more items button for ItineraryField',
  },
  clear: {
    id: 'sharedFlows:button.clear',
    defaultMessage: 'Clear',
    description: 'Global - buttons: Clear button',
  },
  backToExtras: {
    id: 'sharedFlows:button.backToExtras',
    defaultMessage: 'Back to extras',
    description: 'Global - buttons: Back to extras button',
  },
})

const labels = defineMessages({
  cardNumber: {
    id: 'sharedFlows:label.cardNumber',
    defaultMessage: 'Card number',
    description: 'Global - labels: Label for CardNumber',
  },
  expirationDate: {
    id: 'sharedFlows:label.expirationDate',
    defaultMessage: 'Expiration date',
    description: 'Global - labels: Label for Expiration Date',
  },
  cvc: {
    id: 'sharedFlows:label.cvc',
    defaultMessage: 'CVC',
    description: 'Global - labels: Label for CVC',
  },
  firstName: {
    id: 'sharedFlows:label.firstName',
    defaultMessage: 'First name',
    description: 'Global - labels: Label for first name',
  },
  lastName: {
    id: 'sharedFlows:label.lastName',
    defaultMessage: 'Last name',
    description: 'Global - labels: Label for last name',
  },
  email: {
    id: 'sharedFlows:label.email',
    defaultMessage: 'Email',
    description: 'Global - labels: Label for email',
  },
  phoneNumber: {
    id: 'sharedFlows:label.phoneNumber',
    defaultMessage: 'Phone number',
    description: 'Global - labels: Label for phone number',
  },
  nationality: {
    id: 'sharedFlows:label.nationality',
    defaultMessage: 'Nationality',
    description: 'Global - labels: Label for nationality',
  },
  loading: {
    id: 'sharedFlows:label.loading',
    defaultMessage: 'Loading',
    description: 'Global - label: Label for loading',
  },
  agreeToTermsAndPolicies: {
    id: 'sharedFlows:label.agreeToTermsAndPolicies',
    defaultMessage:
      'I agree to have read the [Terms & Cancellation Policy](https://www.bluelagoon.com/legal/blue-lagoon-terms-and-cancellation-policy), [Privacy Policy](https://www.bluelagoon.com/legal/privacy-policy) & [Safety Standards](https://www.bluelagoon.com/legal/safety-standards).',
    description: 'Global - labels: Label for agreeing to terms and policies',
  },
  agreeToTermsAndPoliciesHighland: {
    id: 'sharedFlows:label.agreeToTermsAndPoliciesHighland',
    defaultMessage:
      'I agree to have read the [Terms & Cancellation Policy](https://highlandbase.is/online-booking-terms-and-conditions) and [Privacy Policy](https://highlandbase.is/about/privacy-policy).',
    description:
      'Global - labels: Label for agreeing to terms and policies for Highlandbase hotel',
  },
  agreeToTermsAndPoliciesHighlandSpa: {
    id: 'sharedFlows:label.agreeToTermsAndPoliciesHighlandSpa',
    defaultMessage:
      'I agree to have read the [Terms & Cancellation Policy](https://highlandbase.is/online-booking-terms-and-conditions) and [Privacy Policy](https://highlandbase.is/about/privacy-policy).',
    description:
      'Global - labels: Label for agreeing to terms and policies for Highlandbase Spa',
  },
  agreeToTermsAndPoliciesSilica: {
    id: 'sharedFlows:label.agreeToTermsAndPoliciesSilica',
    defaultMessage:
      'I agree to have read the [Terms & Cancellation Policy](https://www.bluelagoon.com/legal/silica-hotel-terms-and-conditions), [Privacy Policy](https://www.bluelagoon.com/legal/privacy-policy) & [Safety Standards](https://www.bluelagoon.com/legal/safety-standards).',
    description:
      'Global - labels: Label for agreeing to terms and policies for Silica Hotel',
  },
  agreeToTermsAndPoliciesRetreat: {
    id: 'sharedFlows:label.agreeToTermsAndPoliciesRetreat',
    defaultMessage:
      'I agree to have read the [Terms & Cancellation Policy](https://www.bluelagoon.com/legal/retreat-hotel-terms-and-conditions), [Privacy Policy](https://www.bluelagoon.com/legal/privacy-policy) & [Safety Standards](https://www.bluelagoon.com/legal/safety-standards).',
    description:
      'Global - labels: Label for agreeing to terms and policies for Retreat Hotel',
  },
  wishToReceiveNewsletter: {
    id: 'sharedFlows:label.receiveNewsLetter',
    defaultMessage:
      'I want to receive your newsletter and explore the wonders of Blue Lagoon Iceland.',
    description: 'Global - labels: Label for wanting to receive newsletter',
  },
  requiredAsterikExplanation: {
    id: 'sharedFlows:label.requiredAsterik',
    defaultMessage: '* required field',
    description:
      'Globals - labels: Label to explain that asterisk symbol means required field',
  },
  suggestionLabel: {
    id: 'sharedFlows:label.suggestionLabel',
    defaultMessage: 'We suggest the following',
    description:
      'Global - labels: Label for recommended times in availableDateTimesField',
  },
  customizationLabel: {
    id: 'sharedFlows:label.customizationLabel',
    defaultMessage: 'Or would you like to customize your schedule?',
    description:
      'Global - labels: Label for customizing date and time in availableDateTimesField',
  },
  lavaReservation: {
    id: 'sharedFlows:label.lavaReservation',
    defaultMessage: 'Lava reservation',
    description:
      'Global - labels: Label for customizing date and time in DateTimeSelectField for lava reservation',
  },
  pickupTime: {
    id: 'sharedFlows:label.pickupTime',
    defaultMessage: 'Pickup time',
    description:
      'Global - labels: Label for customizing date and time in DateTimeSelectField for pickup time',
  },
  departureTime: {
    id: 'sharedFlows:label.departureTime',
    defaultMessage: 'Departure time',
    description:
      'Global - label: Label for customizing date and time in DateTimeSelectField for departure time',
  },
  travelTime: {
    id: 'sharedFlows:label.travelTime',
    defaultMessage: 'Travel time',
    description:
      'Global - labels: Label for Travel time the in bus transfer flow',
  },
  selectDate: {
    id: 'sharedFlows:label.selectDate',
    defaultMessage: 'Select date',
    description: 'Global - label: Label for select date in DateTimeSelectField',
  },
  selectTime: {
    id: 'sharedFlows:label.selectTime',
    defaultMessage: 'Select time',
    description:
      'Global - labels: Label for select time in DateTimeSelectField',
  },
  currentReservationLabel: {
    id: 'sharedFlows:label.currentReservationLabel',
    defaultMessage: 'Your current reservation',
    description:
      'Global - labels: Label for current/previous reservation in DateTimeSelectField',
  },
  selectLimitedAvailability: {
    id: 'sharedFlows:label.selectLimitedAvailability',
    defaultMessage: '{time} ({available} openings left)',
    description:
      'Global - labels: Select label when there is limited availability in DateTimeSeletField -> HH:mm (X openings left)',
  },
  overviewNewBooking: {
    id: 'sharedFlows:label.overviewNewBooking',
    defaultMessage: 'New booking',
    description:
      'Global - labels: Title of table on overview screen that newly edited booking when users are editing something',
  },
  overviewPreviousBooking: {
    id: 'sharedFlows:label.overviewPreviousBooking',
    defaultMessage: 'Your previous booking',
    description:
      'Global - labels: Title of table on overview screen that shows previous booking when users are editing something',
  },
  overviewUnchanged: {
    id: 'sharedFlows:label.overviewUnchanged',
    defaultMessage: 'Unchanged',
    description:
      'Global - labels: Title of table on overview screen that shows unchanged items when editing hotel package/room/dates',
  },
  overviewSummaryTitleDateTime: {
    id: 'sharedFlows:label.overviewSummaryTitleDateTime',
    defaultMessage: '{title}, {date} at {time}',
    description:
      'Global - labels: Overview screen, activity title, date and time',
  },
  total: {
    id: 'sharedFlows:label.total',
    defaultMessage: 'Total: {price}',
    description: 'Global - labels: Label for total price',
  },
  chargedAs: {
    id: 'sharedFlows:label.chargedAs',
    defaultMessage: '(Charged as {price})',
    description: 'Global - labels: Label for charged as price',
  },
  chargedUponArrival: {
    id: 'sharedFlows:label.chargedUponArrival',
    defaultMessage: 'Charged upon arrival',
    description: 'Global - labels: Label for charged upon arrival',
  },
  chargedBeforeArrivalHotel: {
    id: 'sharedFlows:label.chargedBeforeArrivalHotel',
    defaultMessage: 'Charged 10 days before arrival',
    description:
      'Global - labels: Label for charged 10 days before arrival for hotels',
  },
  selected: {
    id: 'sharedFlows:label.selected',
    defaultMessage: 'Selected',
    description:
      'Global - labels: Selected label - used in subflows and packages step',
  },
  inCart: {
    id: 'sharedFlows:label.inCart',
    defaultMessage: 'In cart',
    description: 'Global - labels: In cart label - used in subflows',
  },
  SILICA: {
    id: 'sharedFlows:label.SILICA',
    defaultMessage: 'Silica Hotel',
    description: 'Global - labels: Silica Hotel name',
  },
  RETREAT: {
    id: 'sharedFlows:label.RETREAT',
    defaultMessage: 'The Retreat',
    description: 'Global - labels: The Retreat name',
  },
  KERLINGARFJOLL: {
    id: 'sharedFlows:label.KERLINGARFJOLL',
    defaultMessage: 'Highland Base',
    description: 'Global - labels: The highland name',
  },
  BlueLagoon: {
    id: 'sharedFlows:label.BlueLagoon',
    defaultMessage: 'The Blue Lagoon',
    description: 'Global - labels: The Blue Lagoon name',
  },
  HighlandBase: {
    id: 'sharedFlows:label.HighlandBase',
    defaultMessage: 'Highland Base',
    description: 'Global - labels: Highland Base name',
  },
  included: {
    id: 'sharedFlows:label.included',
    defaultMessage: 'Included',
    description: 'Global - labels: Included',
  },
  summary: {
    id: 'sharedFlows:label.summary',
    defaultMessage: 'Summary:',
    description: 'Global - labels: Summary',
  },
  package: {
    id: 'sharedFlows:label.package',
    defaultMessage: 'Package',
    description: 'Global - labels: Package',
  },
  room: {
    id: 'sharedFlows:label.room',
    defaultMessage: 'Room',
    description: 'Global - labels: Room',
  },
  roomAddons: {
    id: 'sharedFlows:label.roomAddons',
    defaultMessage: 'Room Add-ons',
    description: 'Global - labels: Room Add-ons',
  },
  products: {
    id: 'sharedFlows:label.products',
    defaultMessage: 'Products',
    description: 'Global - labels: Products',
  },
  airportPickup: {
    id: 'sharedFlows:label.airportPickup',
    defaultMessage: 'Airport pickup',
    description:
      'Global - labels: Airport pickup - used on confirmation screens',
  },
  airportDropoff: {
    id: 'sharedFlows:label.airportDropoff',
    defaultMessage: 'Airport dropoff',
    description:
      'Global - labels: Airport dropoff - used on confirmation screens',
  },
  dates: {
    id: 'sharedFlows:label.dates',
    defaultMessage: 'Dates',
    description: 'Global - labels: Dates',
  },
  guests: {
    id: 'sharedFlows:label.guests',
    defaultMessage: 'Guests',
    description: 'Global - labels: Guests',
  },
  admission: {
    id: 'sharedFlows:label.admission',
    defaultMessage: 'Admission',
    description: 'Global - labels: Admission',
  },
  product: {
    id: 'sharedFlows:label.product',
    defaultMessage: 'Product',
    description: 'Global - labels: Product',
  },
  quantity: {
    id: 'sharedFlows:label.quantity',
    defaultMessage: 'Quantity',
    description: 'Global - labels: Quantity',
  },
  dateAndTime: {
    id: 'sharedFlows:label.dateAndTime',
    defaultMessage: 'Date & Time',
    description: 'Global - labels: Date & Time',
  },
  date: {
    id: 'sharedFlows:label.date',
    defaultMessage: 'Date',
    description: 'Global - labels: Date',
  },
  time: {
    id: 'sharedFlows:label.time',
    defaultMessage: 'Time',
    description: 'Global - labels: Time',
  },
  pickup: {
    id: 'sharedFlows:label.pickup',
    defaultMessage: 'Pickup',
    description: 'Global - labels: Pickup',
  },
  dropoff: {
    id: 'sharedFlows:label.dropoff',
    defaultMessage: 'Dropoff',
    description: 'Global - labels: Dropoff',
  },
  chooseDate: {
    id: 'sharedFlows:label.chooseDate',
    defaultMessage: 'Choose date',
    description: 'Global - labels: Choose date',
  },
  chooseTime: {
    id: 'sharedFlows:label.chooseTime',
    defaultMessage: 'Choose Time',
    description: 'Global - labels: Choose Time',
  },
  promoCode: {
    id: 'sharedFlows:label.promoCode',
    defaultMessage: 'Promo code / Gift card number',
    description: 'Global - labels: Promo code / Gift card number',
  },
  promoCodeActive: {
    id: 'sharedFlows:label.promoCodeActive',
    defaultMessage: 'Promo {promoCode} active.',
    description: 'Global - labels: Label for active promo code',
  },
  giftCardActive: {
    id: 'sharedFlows:label.giftCardActive',
    defaultMessage:
      'Gift card: {giftCard}. Remaining: {remaining} - Used: {chargeAmount}',
    description: 'Global - labels: Label for active giftcard',
  },
  giftCard: {
    id: 'sharedFlows:label.giftCard',
    defaultMessage: 'Gift card',
    description: 'Global - labels: Label for giftcard, e.g. in cart',
  },
  couponCodeDiscount: {
    id: 'sharedFlows:label.couponCodeDiscount',
    defaultMessage: 'Coupon code: {couponCode} Discount:',
    description: 'Global - labels: Label for coupon discount in cart',
  },
  membershipPhoneNumber: {
    id: 'sharedFlows:label.membershipPhoneNumber',
    defaultMessage: 'Cardholder phone number',
    description: 'Global - labels: Label for phone number when signing in',
  },
  noCode: {
    id: 'sharedFlows:label.noCode',
    defaultMessage: 'Did you not receive a code?',
    description:
      'Global - labels: Used for membership login if user didnt receive sms',
  },
  codeSentTo: {
    id: 'sharedFlows:label.codeSentTo',
    defaultMessage: 'A confirmation number sent to the phone number {phone}',
    description:
      'Global - labels: Used for membership login when code was sent',
  },
  items: {
    id: 'sharedFlows:label.items',
    defaultMessage: '{quantity} items',
    description: 'Global - labels: Label for number of items',
  },
  addingToYourBooking: {
    id: 'sharedFlows:label.addingToYourBooking',
    defaultMessage: 'Adding to your booking',
    description:
      'Gobal - labels: Label of table on overview screen when payment is enabled',
  },
  numberOfGuests: {
    id: 'sharedFlows:label.numberOfGuests',
    defaultMessage: 'Number of guests',
    description: 'Gobal - labels: Label for number of guests',
  },
  typeOfActivity: {
    id: 'sharedFlows:label.typeOfActivity',
    defaultMessage: 'Type of {activity}',
    description:
      'Gobal - labels: Label for type of activity, e.g. "Type of massage"',
  },
  massage: {
    id: 'sharedFlows:label.massage',
    defaultMessage: 'massage',
    description: 'Gobal - labels: Used for type of activity, "Type of massage"',
  },
  floatTherapy: {
    id: 'sharedFlows:label.floatTherapy',
    defaultMessage: 'float therapy',
    description:
      'Gobal - labels: Used for type of activity, "Type of float therapy"',
  },
  nameOfGuest: {
    id: 'sharedFlows:bookingDetails.labels.nameOfGuest',
    defaultMessage: 'Name of guest',
    description: 'Global: Name of guest',
  },
})

const placeholders = defineMessages({
  cardNumber: {
    id: 'sharedFlows:placeholder.cardNumber',
    defaultMessage: '**** **** **** ****',
    description: 'Global - placeholders: Placeholder for CardNumber',
  },
  expirationDate: {
    id: 'sharedFlows:placeholder.expirationDate',
    defaultMessage: 'DD/MM',
    description: 'Global - placeholders: Placeholder for Expiration Date',
  },
  cvc: {
    id: 'sharedFlows:placeholder.cvc',
    defaultMessage: '***',
    description: 'Global - placeholders: Placeholder for CVC',
  },
  firstName: {
    id: 'sharedFlows:placeholder.firstName',
    defaultMessage: 'First name',
    description: 'Global - placeholders: Placeholder for first name',
  },
  lastName: {
    id: 'sharedFlows:placeholder.lastName',
    defaultMessage: 'Last name',
    description: 'Global - placeholders: Placeholder for last name',
  },
  email: {
    id: 'sharedFlows:placeholder.email',
    defaultMessage: 'Enter your email',
    description: 'Global - placeholders: Placeholder for email',
  },
})

const breadcrumbs = defineMessages({
  checkout: {
    id: 'sharedFlows:breadcrumbs.checkout.title',
    defaultMessage: 'Checkout',
    description: 'Global - breadcrumbs: Title of checkout steps breadcrumb',
  },
  overview: {
    id: 'sharedFlows:breadcrumbs.overview',
    defaultMessage: 'Overview',
    description: 'Global - breadcrumbs: Overview breadcrumb label',
  },
})

const warnings = defineMessages({
  bookingTooCloseToAllowUpgrade: {
    id: 'sharedFlows:bookingTooCloseToAllowUpgrade',
    defaultMessage:
      'Unfortunately, we cannot add this upgrade to your booking on such short notice. Please contact reception when you arrive and we will try to fulfill your requests there.',
    description:
      'Global - warnings: Warning message if users try to add upgrades to a booking when it is close to the entry date',
  },
  checkoutAlreadyProcessed: {
    id: 'sharedFlows:warning.checkoutAlreadyProcessed',
    defaultMessage: 'Your payment was successful.',
    description:
      'Global: Warning message if users already have a successful booking when trying to initialize payment again',
  },
})

const disclaimers = defineMessages({
  massageFloatChildrenDisclaimerLabel: {
    id: 'sharedFlows:massageFloatChildrenDisclaimerLabel',
    defaultMessage: 'Children and in-water treatments',
    description:
      'Global - disclaimers: Label for disclaimer about children when booking massage or float',
  },
  massageFloatChildrenDisclaimer: {
    id: 'sharedFlows:massageFloatChildrenDisclaimer',
    defaultMessage:
      'Children between the ages of 8-13 can have an in-water treatment but a parent, guardian, or adult supervisor must be there during the entire massage, and it can only last for a maximum of 30 minutes. Children aged 14 and over follow the same rules as adults. However, we do not offer massages for children aged 7 and under. \n\nPlease make sure your child has supervision during your massage.',
    description:
      'Global - disclaimers: Disclaimer for when all guests on a booking are busy',
  },
  allGuestsBusyDisclaimer: {
    id: 'sharedFlows:allGuestsBusyDisclaimer',
    defaultMessage:
      'You already have an activity booked at this time, please select a different time',
    description:
      'Global - disclaimers: Disclaimer about children when booking massage or float',
  },
})

const errors = defineMessages({
  required: {
    id: 'sharedFlows:errors.required',
    defaultMessage: 'Required',
    description: 'Global - errors: Error when field is required',
  },
  general: {
    id: 'sharedFlows:errors.general',
    defaultMessage: 'Something went wrong',
    description: 'Global - errors: General error',
  },
  generalTryAgain: {
    id: 'sharedFlows:errors.generalTryAgain',
    defaultMessage: 'Something went wrong, please try again',
    description: 'Global - errors: General error',
  },
  noAvailabilityError: {
    id: 'sharedFlows:errors.noAvailabilityError',
    defaultMessage:
      'Unfortunately there was an error fetching available times. Please contact our support center for help.',
    description:
      'Global - errors: Error for when there are no dates in dateTimeSelectField, for example if the booking is in the past',
  },
  phoneNumber: {
    id: 'sharedFlows:errors.phoneNumber',
    defaultMessage: 'Invalid phone number',
    description: 'Global - errors: Invalid phone number',
  },
  invalidEmail: {
    id: 'sharedFlows:errors.invalidEmail',
    defaultMessage: 'Invalid email',
    description: 'Global - errors: Invalid email',
  },
  cartErrorTitle: {
    id: 'sharedFlows:errors.failedToLoadCartTitle',
    defaultMessage: 'Loading error',
    description: 'Global - errors: Cart loading error title',
  },
  cartErrorDescription: {
    id: 'sharedFlows:errors.failedToLoadCartDescription',
    defaultMessage: 'An error occurred while loading your cart',
    description: 'Global - errors: Cart loading error description',
  },
  noTimeSlotsAvailable: {
    id: 'sharedFlows:errors.noTimeSlotsAvailable',
    defaultMessage: 'There are no available timeslots',
    description:
      'Global - errors: Error for when there are no timeslots available in dateTimeSelectField, for example if booking is late in the evening and no airport dropoffs are after that time',
  },
  noAvailability: {
    id: 'sharedFlows:errors.noAvailability',
    defaultMessage:
      'Sorry, there are no available times on the selected date, please try another date',
    description:
      'Global - errors: Error for when there are no timeslots available in the calendar for a certain date',
  },
  timeFullyBookedError: {
    id: 'sharedFlows:errors.timeFullyBookedError',
    defaultMessage:
      'We are fully booked at your chosen time, please select a different time',
    description:
      'Global - error: Fully booked error message for time slot in SelectDayVisitTimeField',
  },
  availabilityFetchError: {
    id: 'sharedFlows:errors.availabilityFetchError',
    defaultMessage: 'There was an error fetching the availability information',
    description:
      'Global - errors: Availability fetch error in SelectDayVisitTimeField',
  },
  // TODO: use this when unknown error occurs and FlowRenderer.notifyError catches it
  unknownErrorTitle: {
    id: 'sharedFlows:errors.unknownErrorTitle',
    defaultMessage: 'Unknown error',
    description: 'Unknown error that requires booking to be reset title',
  },
  unknownErrorSubtitle: {
    id: 'sharedFlows:errors.unknownErrorSubtitle',
    defaultMessage:
      'Unfortunately an unknown error has occurred and we need to reset your booking. If this error persists, please contact us at contact@bluelagoon.com',
    description: 'Unknown error that requires booking to be reset subtitle',
  },
  unknownErrorHighlandSubtitle: {
    id: 'sharedFlows:errors.unknownErrorHighlandSubtitle',
    defaultMessage:
      'Unfortunately an unknown error has occurred and we need to reset your booking. If this error persists, please contact us at info@highlandbase.is',
    description:
      'Unknown Highland error that requires booking to be reset subtitle',
  },
  firstNameRequired: {
    id: 'sharedFlows:bookingDetails.errors.firstNameRequired',
    defaultMessage: 'First name is required',
    description: 'Booking Details: First name required error',
  },
  lastNameRequired: {
    id: 'sharedFlows:bookingDetails.errors.lastNameRequired',
    defaultMessage: 'Last name is required',
    description: 'Booking Details: Last name required error',
  },
  emailRequired: {
    id: 'sharedFlows:bookingDetails.errors.emailRequired',
    defaultMessage: 'Email is required',
    description: 'Booking Details: Email required error',
  },
  phoneNumberRequired: {
    id: 'sharedFlows:bookingDetails.errors.phoneNumberRequired',
    defaultMessage: 'Phone number is required',
    description: 'Booking Details: First name required error',
  },
  termsRequired: {
    id: 'sharedFlows:bookingDetails.errors.termsRequired',
    defaultMessage: 'You must accept the terms',
    description: 'Booking Details: terms required error',
  },
  cardRequired: {
    id: 'sharedFlows:bookingDetails.errors.cardRequired',
    defaultMessage: 'Card number required',
    description: 'Booking Details: Card required error',
  },
  cardInvalid: {
    id: 'sharedFlows:bookingDetails.errors.cardInvalid',
    defaultMessage: 'Card number is invalid',
    description: 'Booking Details: Card number invalid error',
  },
  cardNotSupported: {
    id: 'sharedFlows:bookingDetails.errors.cardNotSupported',
    defaultMessage: 'Card type is not supported',
    description: 'Booking Details: Card type not supported error',
  },
  expirationRequired: {
    id: 'sharedFlows:bookingDetails.errors.expirationRequired',
    defaultMessage: 'Expriation date required',
    description: 'Booking Details: expiration required error',
  },
  expirationInvalid: {
    id: 'sharedFlows:bookingDetails.errors.expirationInvalid',
    defaultMessage: 'Expiration date is invalid',
    description: 'Booking Details: expiration invalid error',
  },
  cvcRequired: {
    id: 'sharedFlows:bookingDetails.errors.cvcRequired',
    defaultMessage: 'CVC required',
    description: 'Booking Details: cvc required error',
  },
  cvcInvalid: {
    id: 'sharedFlows:bookingDetails.errors.cvcInvalid',
    defaultMessage: 'CVC is invalid',
    description: 'Booking Details: cvc invalid error',
  },
  paymentInitializedError: {
    id: 'sharedFlows:bookingDetails.errors.payment.initialized.error',
    defaultMessage:
      'An error occurred when initializing payment. Please try again.',
    description: 'Booking Details: payment failed to initialize',
  },
  paymentDefaultTitle: {
    id: 'sharedFlows:bookingDetails.errors.payment.default.title',
    defaultMessage: 'Payment failed',
    description: 'Booking Details: payment failed error title',
  },
  paymentDefaultMessage: {
    id: 'sharedFlows:bookingDetails.errors.payment.default.message',
    defaultMessage:
      'An unexpected error occurred while processing your payment request. Please try again.',
    description: 'Booking Details: payment failed error message',
  },
  paymentDefaultButtonLabel: {
    id: 'sharedFlows:bookingDetails.errors.payment.default.buttonLabel',
    defaultMessage: 'Try again',
    description: 'Booking Details: payment failed error button label',
  },
  onePromoPerBooking: {
    id: 'sharedFlows:bookingDetails.errors.onePromoPerBooking',
    defaultMessage: 'Only one Promo code per booking',
    description: 'Booking Details: one promo per booking error',
  },
  oneGiftcardPerBooking: {
    id: 'sharedFlows:bookingDetails.errors.oneGiftcardPerBooking',
    defaultMessage: 'Only one gift card per booking',
    description: 'Booking Details: one gift card per booking error',
  },
  notValid: {
    id: 'sharedFlows:bookingDetails.errors.notValid',
    defaultMessage: 'Not valid',
    description:
      'Booking Details: not valid - used for gift cards and promo codes',
  },
  confirmationCodeRequired: {
    id: 'sharedFlows:bookingDetails.errors.confirmationCodeRequired',
    defaultMessage: 'Please enter confirmation code',
    description: 'Booking Details: confirmation code required',
  },
  invalidConfirmationCode: {
    id: 'sharedFlows:bookingDetails.errors.invalidConfirmationCode',
    defaultMessage: 'Invalid confirmation code',
    description: 'Booking Details: invalid confirmation code',
  },
  somethingWentWrong: {
    id: 'sharedFlows:errors.somethingWentWrong',
    defaultMessage: 'Something went wrong',
    description:
      'Global - errors: Something went wrong. Used for example on error step in massage flows',
  },
  paymentSuccessAddFailed: {
    id: 'sharedFlows:errors.paymentSuccessAddFailed',
    defaultMessage:
      'We encountered an issue processing your request. Don’t worry, we’re refunding your payment right away. Your original booking remains unchanged. Please give it another try in a few minutes.',
    description:
      'Global - errors: Error message displayed when payment is successful but adding to booking fails',
  },
  contactSupportCenter: {
    id: 'sharedFlows:massage.contactSupportCenter',
    defaultMessage:
      'Need help right now? We’re here for you at [contact@bluelagoon.is](mailto:contact@bluelagoon.is)',
    description:
      'Global - errors: Error message that directs user to the support center',
  },
})

export const globalBookingMessages = {
  text,
  buttons,
  labels,
  placeholders,
  breadcrumbs,
  warnings,
  disclaimers,
  errors,
}
