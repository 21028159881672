import { ElementType, HTMLAttributes, ReactNode } from 'react'
import kebabCase from 'lodash/kebabCase'
import styled, {
  css,
  CSSProperties,
  FlattenSimpleInterpolation,
} from 'styled-components'
// styled-components-breakpoint v.2.0.2 does not have types and v3 preview has types but development was dropped 5 years ago
// We should use // @ts-expect-error but it causes an error on apps
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore Apps trigger an error on @ts-expect-error because they are not as strict as ui-primitives
import { map } from 'styled-components-breakpoint'

import { BreakpointMap } from './utils/breakpoints'
import { spacing } from './utils/spacing'

type StyleProp<T extends keyof CSSProperties> =
  | CSSProperties[T]
  | BreakpointMap<CSSProperties[T]>

type StyleProps = {
  position?: StyleProp<'position'>
  bottom?: StyleProp<'bottom'>
  left?: StyleProp<'left'>
  right?: StyleProp<'right'>
  top?: StyleProp<'top'>
  zIndex?: StyleProp<'zIndex'>
  maxWidth?: StyleProp<'maxWidth'>
  margin?: StyleProp<'margin'>
  marginTop?: StyleProp<'marginTop'>
  marginRight?: StyleProp<'marginRight'>
  marginBottom?: StyleProp<'marginBottom'>
  marginLeft?: StyleProp<'marginLeft'>
  padding?: StyleProp<'padding'>
  paddingTop?: StyleProp<'paddingTop'>
  paddingRight?: StyleProp<'paddingRight'>
  paddingBottom?: StyleProp<'paddingBottom'>
  paddingLeft?: StyleProp<'paddingLeft'>
  textAlign?: StyleProp<'textAlign'>
  $height?: StyleProp<'height'>
  $width?: StyleProp<'width'>
  minWidth?: StyleProp<'minWidth'>
  display?: StyleProp<'display'>
  opacity?: StyleProp<'opacity'>
  gridArea?: StyleProp<'gridArea'>
  gap?: StyleProp<'gap'>
  rowGap?: StyleProp<'rowGap'>
  flex?: StyleProp<'flex'>
  flexDirection?: StyleProp<'flexDirection'>
  flexBasis?: StyleProp<'flexBasis'>
  flexWrap?: StyleProp<'flexWrap'>
  justifyContent?: StyleProp<'justifyContent'>
  alignItems?: StyleProp<'alignItems'>
  boxShadow?: StyleProp<'boxShadow'>
  background?: StyleProp<'background'>
  color?: StyleProp<'color'>
  overflow?: StyleProp<'overflow'>
  borderRadius?: StyleProp<'borderRadius'>
}

type BoxProps = {
  as?: ElementType
  children?: ReactNode
} & StyleProps &
  HTMLAttributes<HTMLDivElement>

const cssProps: Record<keyof StyleProps, keyof CSSProperties> = {
  position: 'position',
  bottom: 'bottom',
  left: 'left',
  right: 'right',
  top: 'top',
  zIndex: 'zIndex',
  maxWidth: 'maxWidth',
  margin: 'margin',
  marginTop: 'marginTop',
  marginRight: 'marginRight',
  marginBottom: 'marginBottom',
  marginLeft: 'marginLeft',
  padding: 'padding',
  paddingTop: 'paddingTop',
  paddingRight: 'paddingRight',
  paddingBottom: 'paddingBottom',
  paddingLeft: 'paddingLeft',
  textAlign: 'textAlign',
  $width: 'width',
  minWidth: 'minWidth',
  $height: 'height',
  display: 'display',
  opacity: 'opacity',
  gridArea: 'gridArea',
  gap: 'gap',
  rowGap: 'rowGap',
  flex: 'flex',
  flexDirection: 'flexDirection',
  flexBasis: 'flexBasis',
  flexWrap: 'flexWrap',
  justifyContent: 'justifyContent',
  alignItems: 'alignItems',
  boxShadow: 'boxShadow',
  background: 'background',
  color: 'color',
  overflow: 'overflow',
  borderRadius: 'borderRadius',
}

const spacingProperties = new Set([
  'margin',
  'marginTop',
  'marginRight',
  'marginBottom',
  'marginLeft',
  'padding',
  'paddingTop',
  'paddingRight',
  'paddingBottom',
  'paddingLeft',
  'gap',
])

const defaultStyles = {
  $width: '100%',
  display: 'flex',
  flexDirection: 'column',
}

function applyStyles(props: StyleProps): FlattenSimpleInterpolation {
  const combinedStyles = { ...defaultStyles, ...props }
  return (
    Object.keys(combinedStyles) as Array<keyof typeof combinedStyles>
  ).reduce((acc, key) => {
    const prop = combinedStyles[key]
    const cssProperty = cssProps[key]
    if (prop && cssProperty) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const style =
        typeof prop === 'object'
          ? // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            map(
              prop,
              (value: number) => css`
                ${kebabCase(cssProperty)}: ${spacingProperties.has(key) &&
                typeof value === 'number' &&
                spacing?.[value]
                  ? spacing[value]
                  : value};
              `
            )
          : css`
              ${kebabCase(cssProperty)}: ${spacingProperties.has(key) &&
              typeof prop === 'number' &&
              spacing?.[prop]
                ? spacing[prop]
                : prop};
            `
      return css`
        ${acc}${style as string}
      `
    }
    return acc
  }, css``)
}

/* The double typing is a typescript react quirk describes here: https://styled-components.com/docs/api#caveat-with-function-components */
export const Box: React.FC<BoxProps> = styled.div<BoxProps>`
  ${({ theme, ...props }) => applyStyles(props)}
`
