import styled, { css } from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { theme } from 'bl-common/src/styles/theme'
import { PartialBookingEngine } from 'bl-common/src/styles/types'
import { media, mediaObj } from 'bl-common/src/utils/media'

type CardProps = {
  themeStyle?: PartialBookingEngine['cardButtonField']['card']
  focusCard?: boolean
}

export const Card = styled.article<CardProps>`
  background: ${props =>
    props?.focusCard
      ? props?.themeStyle?.focusBackground ??
        'linear-gradient(311.69deg, rgba(216, 231, 230, 0.5) 2.43%, rgba(245, 246, 245, 0.5) 97.27%)'
      : props?.themeStyle?.background ?? colors.white};
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
  color: ${props => props?.themeStyle?.color ?? colors.dark};
  display: flex;
  position: relative;

  border-radius: ${props => props?.themeStyle?.borderRadius ?? 0}px;
  overflow: ${props => props?.themeStyle?.overflow ?? 'visible'};
`

export const Content = styled.div`
  flex: 1 1 auto;
  padding: 20px;
  overflow: hidden;

  ${media.md(css`
    padding: ${({ theme }) => `${theme.spacing[1.5]}`};
  `)}
`

export const TopWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing[0.5],
  flexWrap: 'wrap',
  alignItems: 'center',
})

export const Footer = styled.footer`
  display: flex;
  justify-content: space-between;

  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 2px;
`

const buttonStyles = css<{
  isDisabled: boolean
  inCart?: boolean
  themeStyle?: PartialBookingEngine['cardButtonField']['button']
}>`
  color: ${({ isDisabled }) => (isDisabled ? colors.grey : colors.deepBlue)};
  cursor: ${({ isDisabled, inCart }) =>
    isDisabled || inCart ? 'default' : 'pointer'};

  font-weight: ${props => props?.themeStyle?.fontWeight};
  color: ${props => props?.themeStyle?.color ?? colors.deepBlue};

  ${media.md(css`
    &:hover {
      text-decoration: ${({
        isDisabled,
        inCart,
      }: {
        isDisabled: boolean
        inCart?: boolean
      }) => (isDisabled || inCart ? 'none' : 'underline')};
    }
  `)}
`

export const Button = styled(Type)`
  ${buttonStyles}
  &::after {
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
`
export const SecondaryButton = styled(Type)`
  ${buttonStyles}
  z-index: 2;
`

export const LinkWrapper = styled(Type)`
  ${buttonStyles}
  padding-left: ${({ theme }) => `${theme.spacing[2]}`};
  &::after {
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
`

export const ButtonContent = styled.div`
  display: flex;
  align-items: center;
`

export const ImageWrap = styled.div`
  position: relative;
  flex: 1 0 100%;
  max-width: 133px;

  ${media.md(css`
    max-width: 161px;
  `)}
  ${media.xl(css`
    max-width: 20%;
  `)}
`

export const Line = styled.span<{
  selected?: boolean
  themeStyle?: PartialBookingEngine['cardButtonField']['line']
}>`
  display: none;
  height: 3px;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;

  background: ${props => props?.themeStyle?.background ?? '#52a5b8'};

  ${({ selected = false }) =>
    selected &&
    css`
      display: block;
    `}
`
export const discountPrice = styled.span({
  marginLeft: 5,
})

export const PricesWrapper = styled.span({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',

  [mediaObj.sm]: {
    justifyContent: 'flex-end',
  },
})
