import { themeToPresetsMap } from 'bl-common/src/elements/ProgressButton/ProgressButton'
import { IFormSection } from 'bl-common/src/generated/contentful'
import { ScreenTheme } from 'bl-common/src/styles/types'
import { Form } from 'bl-common/src/units/Form/Form'
import {
  buildButton,
  buildCustomField,
  buildFlow,
  buildHeading,
  buildScreenWithImageLayout,
  FlowControl,
} from 'bl-flows-core'

import { globalBookingMessages } from '../../messages'
import { contactFormMessages } from './messages/contactFormMessages'

const PhoneLinkComponent =
  '<a style="text-decoration:underline" href="tel:+3544208800">+354 420 8800</a>'

interface ContactFormInitialData {
  form: IFormSection
  validate: (values: Record<string, string>) => Record<string, string>
  onSubmit: (
    values: Record<string, string>,
    formFields: Record<string, any>,
    formId: string
  ) => Promise<boolean>
  recaptchaKey: string
  customerEmail: string
  bookingIsTooClose: boolean
  isHotelBooking: boolean
  screenTheme: ScreenTheme
}

export const buildContactFormFlow = (
  onSuccess: () => void,
  onClose: (control: FlowControl) => void,
  initialData: ContactFormInitialData
) =>
  buildFlow({
    id: 'contactFlow',
    routerSettings: {
      updateHistory: false,
    },
    settings: {
      resetWhenOpened: true,
    },
    children: [
      buildScreenWithImageLayout({
        id: 'contact-form',
        subType: 'section',
        layoutProps: {
          layoutId: 'contact-image-layout',
          layoutImageSrc:
            initialData?.form?.fields?.image?.fields?.file?.url ||
            'https://images.ctfassets.net/w65k7w0nsb8q/3exKIimKUx5Pt8jqWlE7z3/a86cdb7d9e3be693dfad9b0716b04a5b/The_water.jpg',
        },
        breadcrumb: false,
        columnStyle: initialData.bookingIsTooClose,
        fields: {
          main: [
            ...buildHeading({
              title: control =>
                initialData.bookingIsTooClose
                  ? control.context.t(
                      contactFormMessages.info.bookingToCloseTitle
                    )
                  : initialData.form.fields.title,
              subTitle: control =>
                initialData.bookingIsTooClose
                  ? initialData.isHotelBooking
                    ? control.context.t(
                        contactFormMessages.info.bookingToCloseHotelSubtitle,
                        {
                          phoneNo: PhoneLinkComponent,
                        }
                      )
                    : control.context.t(
                        contactFormMessages.info.bookingToCloseSubtitle,
                        {
                          phoneNo: PhoneLinkComponent,
                        }
                      )
                  : initialData.form.fields.description,
              mtTitle: { xs: 2 },
            }),
            buildCustomField({
              defaultValue: null,
              condition: () => !initialData.bookingIsTooClose,
              props: {
                component: props => (
                  <Form
                    form={initialData.form.fields.form}
                    fullWidthSubmitButton
                    validate={initialData?.validate}
                    onSubmit={initialData?.onSubmit}
                    onSuccess={() => {
                      onSuccess()
                      props.control.nextScreen()
                    }}
                    // deliberately passing formSuccess as undefined as we take care of it
                    // in this flow's next screen.
                    formSuccess={undefined}
                    buttonText={initialData.form.fields.buttonText}
                    recaptchaKey={initialData?.recaptchaKey}
                    hideCaptcha
                    theme={initialData?.screenTheme}
                  />
                ),
              },
            }),
            buildButton({
              condition: () => initialData.bookingIsTooClose,
              props: {
                preset: themeToPresetsMap[initialData?.screenTheme],
                label: control =>
                  control.context.t(globalBookingMessages.buttons.closeWindow),
                onClick: control => onClose(control),
              },
              layout: {
                marginTopAuto: { xs: true },
              },
            }),
          ],
        },
      }),
      buildScreenWithImageLayout({
        id: 'request-confirmed',
        subType: 'section',
        layoutProps: {
          layoutId: 'contact-image-layout',
          layoutImageSrc:
            initialData?.form?.fields?.image?.fields?.file?.url ||
            'https://images.ctfassets.net/w65k7w0nsb8q/3exKIimKUx5Pt8jqWlE7z3/a86cdb7d9e3be693dfad9b0716b04a5b/The_water.jpg',
        },
        columnStyle: true,
        breadcrumb: false,
        fields: {
          main: [
            ...buildHeading({
              title: () =>
                initialData.form.fields.successMessages.fields.heading,
              subTitle: () =>
                initialData.form.fields.successMessages.fields.multilineSubheading.replace(
                  '%email',
                  initialData.customerEmail || ''
                ),
              mtTitle: { xs: 2 },
            }),
            buildButton({
              props: {
                preset: themeToPresetsMap[initialData?.screenTheme],
                label: control =>
                  control.context.t(globalBookingMessages.buttons.closeWindow),
                onClick: control => onClose(control),
              },
              layout: { marginTopAuto: { xs: true } },
            }),
          ],
        },
      }),
    ],
  })
