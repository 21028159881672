import styled, { css } from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import { Reload } from 'bl-common/src/elements/Icons/Reload'
import { media } from 'bl-common/src/utils/media'

export const Table = styled.div`
  width: 100%;
`
export const TableRow = styled.div`
  display: flex;
  justify-content: space-between;
`
export const TableBody = styled.div``

export const DateControls = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) =>
    `${theme.spacing[1]} 0 ${theme.spacing[1]} ${theme.spacing[1.5]}`};
  margin-left: ${({ theme }) => theme.spacing[-1.5]};
`

export const SpinnerWrapper = styled.div`
  display: grid;
  place-items: center;

  margin: 30px 0;
`

export const ControlButton = styled.button`
  cursor: pointer;
  display: flex;
  flex: 0 0 20%;
  height: 25px;
  opacity: 1;
  display: flex;
  align-items: center;

  :disabled {
    cursor: default;
    opacity: 0;
  }
`

export const Weekday = styled.span`
  color: ${colors.midGrey};
  margin-right: ${({ theme }) => theme.spacing[0.5]};
`

export const Date = styled.span`
  color: ${colors.deepBlue};
`

export const TableHeader = styled.div`
  background: rgb(255, 255, 255);
`
export const ColumnLabels = styled.div`
  border-top: 1px solid #d2d2d2;
  border-bottom: 1px solid transparent;
  display: flex;

  padding: ${({ theme }) => `${theme.spacing[1]} 0 ${theme.spacing[1]}`};

  ${media.bmd(css`
    border-bottom: 1px solid #d2d2d2;
  `)}
`

export const DayPartLabel = styled.div`
  display: flex;
  flex: 0 0 50px;
  text-transform: capitalize;

  ${media.bmd(css`
    flex: 0 0 80px;
  `)}
`
export const PackageTypeHeader = styled.div`
  display: flex;
  flex: 1 0 0;
  justify-content: center;
`

export const ReloadButton = styled.button`
  cursor: pointer;
  align-items: flex-start;
  display: flex;
  padding-top: ${({ theme }) => theme.spacing[0.5]};
`

export const Icon = styled(Reload)`
  flex: 0 0 ${({ theme }) => theme.spacing[1.5]};
  padding-top: 2px;

  ${media.md(css`
    flex: 0 0 ${({ theme }) => theme.spacing[1]};
  `)}
`

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 800px;
`

export const TimeSlotContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: -8%;
  align-items: center;
  justify-content: center;
  transition: background-color 100ms ease-in-out;

  &:hover {
    background-color: #efefef;
  }
`

interface TimeSlotCellProps {
  isActive?: boolean
}

const timeSlotHoverStyles = css`
  background-color: ${colors.deepBlue};
  color: white;
`

export const TimeSlotCell = styled.div<TimeSlotCellProps>`
  text-align: center;
  flex: 1;
  padding: ${({ theme }) => `${theme.spacing[1]} ${theme.spacing[1.5]}`};
  transition: background-color 50ms ease-in-out, color 50ms ease-in-out;
  position: relative;

  &:first-child {
    max-width: calc(80px + 8%);
  }

  &:hover:not(:first-child) {
    ${timeSlotHoverStyles};
  }

  ${props =>
    props.isActive &&
    css`
      ${timeSlotHoverStyles};

      &:after {
        content: '✓';
        z-index: 5;
        position: absolute;
        padding: ${({ theme }) => `${theme.spacing[1]} ${theme.spacing[1.5]}`};
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: ${colors.deepBlue};
      }
    `}
`
