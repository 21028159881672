import styled from 'styled-components'

import { Type } from 'bl-common/src/elements/Typography/Typography'
import { theme } from 'bl-common/src/styles/theme'
import { buildCardButtonField, FieldRenderer, FlowControl } from 'bl-flows-core'
import { ActivityProductAvailabilityQuery, Property } from 'bl-graphql'
import { PRODUCT_IDS, ProductId } from 'bl-utils/src/ProductIds'

import { globalBookingMessages } from '../messages'
import { privateTransferMessages } from '../subflows/privateTransfer/messages/privateTransferMessages'

interface PrivateTransferServioCardsProps {
  control: FlowControl
  transferAvailability: ActivityProductAvailabilityQuery['activityProductAvailability']
}

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing[1.5],
})

const Arrival = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing[0.5],
})

const Departure = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing[0.5],
})

const Cards = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing[1],
})

export const PrivateTransferServioCards: React.FC<PrivateTransferServioCardsProps> =
  ({ control, transferAvailability }: PrivateTransferServioCardsProps) => {
    const property = control.flow.setupHook?.bookingInfo?.entryDate
      ? control.context.t(globalBookingMessages.labels.BlueLagoon)
      : control.flow.setupHook?.bookingInfo?.property.toUpperCase() ===
        Property.Silica
      ? control.context.t(globalBookingMessages.labels.SILICA)
      : control.context.t(globalBookingMessages.labels.RETREAT)

    const onArrivalClick = (id: ProductId) => {
      if (control.screen.state?.selectedArrival === id) {
        control.screen.setState({
          selectedArrival: null,
        })
      } else {
        control.screen.setState({
          selectedArrival: id,
        })
      }
    }

    const onDepartureClick = (id: ProductId) => {
      if (control.screen.state?.selectedDeparture === id) {
        control.screen.setState({
          selectedDeparture: null,
        })
      } else {
        control.screen.setState({
          selectedDeparture: id,
        })
      }
    }

    const getLowestPrice = (productId: ProductId) => {
      return transferAvailability
        ?.filter(item => item.productId === productId)
        ?.reduce((lowest, curr) => {
          if (lowest) {
            return lowest < curr.price ? lowest : curr.price
          }
          return curr.price
        }, 0)
    }

    return (
      <Container>
        <Arrival>
          <Type preset="textLarge" weight="bold">
            {control.context.t(privateTransferMessages.info.arrivalLabel)}
          </Type>
          <Cards>
            <FieldRenderer
              control={control}
              item={buildCardButtonField({
                props: {
                  title: control =>
                    control.context.t(
                      privateTransferMessages.info.arrivalServioSmall
                    ),
                  ctaLabel: control =>
                    control.context.t(globalBookingMessages.buttons.select),
                  description: control =>
                    control.context.t(
                      privateTransferMessages.info.arrivalServioDescription,
                      {
                        guests: 2,
                        property,
                      }
                    ),
                  onClick: () => {
                    onArrivalClick(
                      PRODUCT_IDS.PrivateTransferArrivalServioSmall
                    )
                  },
                  priceFormat: control.context.t(
                    privateTransferMessages.info.priceFromFormat
                  ),
                  price: getLowestPrice(
                    PRODUCT_IDS.PrivateTransferArrivalServioSmall
                  ),
                  isClosed: false,
                  inCart:
                    control.screen.state?.selectedArrival ===
                    PRODUCT_IDS.PrivateTransferArrivalServioSmall,
                  inCartLabel: control =>
                    control.context.t(globalBookingMessages.labels.selected),
                },
              })}
            />
            <FieldRenderer
              control={control}
              item={buildCardButtonField({
                props: {
                  title: control =>
                    control.context.t(
                      privateTransferMessages.info.arrivalServioMedium
                    ),
                  ctaLabel: control =>
                    control.context.t(globalBookingMessages.buttons.select),
                  description: control =>
                    control.context.t(
                      privateTransferMessages.info.arrivalServioDescription,
                      {
                        guests: 8,
                        property,
                      }
                    ),
                  onClick: () => {
                    onArrivalClick(
                      PRODUCT_IDS.PrivateTransferArrivalServioMedium
                    )
                  },
                  priceFormat: control.context.t(
                    privateTransferMessages.info.priceFromFormat
                  ),
                  price: getLowestPrice(
                    PRODUCT_IDS.PrivateTransferArrivalServioMedium
                  ),
                  isClosed: false,
                  inCart:
                    control.screen.state?.selectedArrival ===
                    PRODUCT_IDS.PrivateTransferArrivalServioMedium,
                  inCartLabel: control =>
                    control.context.t(globalBookingMessages.labels.selected),
                },
              })}
            />
            <FieldRenderer
              control={control}
              item={buildCardButtonField({
                props: {
                  title: control =>
                    control.context.t(
                      privateTransferMessages.info.arrivalServioLarge
                    ),
                  ctaLabel: control =>
                    control.context.t(globalBookingMessages.buttons.select),
                  description: control =>
                    control.context.t(
                      privateTransferMessages.info.arrivalServioDescription,
                      {
                        guests: 8,
                        property,
                      }
                    ),
                  onClick: () => {
                    onArrivalClick(
                      PRODUCT_IDS.PrivateTransferArrivalServioLarge
                    )
                  },
                  priceFormat: control.context.t(
                    privateTransferMessages.info.priceFromFormat
                  ),
                  price: getLowestPrice(
                    PRODUCT_IDS.PrivateTransferArrivalServioLarge
                  ),
                  isClosed: false,
                  inCart:
                    control.screen.state?.selectedArrival ===
                    PRODUCT_IDS.PrivateTransferArrivalServioLarge,
                  inCartLabel: control =>
                    control.context.t(globalBookingMessages.labels.selected),
                },
              })}
            />
          </Cards>
        </Arrival>

        <Departure>
          <Type preset="textLarge" weight="bold">
            {control.context.t(privateTransferMessages.info.departureLabel)}
          </Type>
          <Cards>
            <FieldRenderer
              control={control}
              item={buildCardButtonField({
                props: {
                  title: control =>
                    control.context.t(
                      privateTransferMessages.info.departureServioSmall
                    ),
                  ctaLabel: control =>
                    control.context.t(globalBookingMessages.buttons.select),
                  description: control =>
                    control.context.t(
                      privateTransferMessages.info.departureServioDescription,
                      {
                        guests: 2,
                        property,
                      }
                    ),
                  onClick: () => {
                    onDepartureClick(
                      PRODUCT_IDS.PrivateTransferDepartureServioSmall
                    )
                  },
                  priceFormat: control.context.t(
                    privateTransferMessages.info.priceFromFormat
                  ),
                  price: getLowestPrice(
                    PRODUCT_IDS.PrivateTransferDepartureServioSmall
                  ),
                  isClosed: false,
                  inCart:
                    control.screen.state?.selectedDeparture ===
                    PRODUCT_IDS.PrivateTransferDepartureServioSmall,
                  inCartLabel: control =>
                    control.context.t(globalBookingMessages.labels.selected),
                },
              })}
            />
            <FieldRenderer
              control={control}
              item={buildCardButtonField({
                props: {
                  title: control =>
                    control.context.t(
                      privateTransferMessages.info.departureServioMedium
                    ),
                  ctaLabel: control =>
                    control.context.t(globalBookingMessages.buttons.select),
                  description: control =>
                    control.context.t(
                      privateTransferMessages.info.departureServioDescription,
                      {
                        guests: 2,
                        property,
                      }
                    ),
                  onClick: () => {
                    onDepartureClick(
                      PRODUCT_IDS.PrivateTransferDepartureServioMedium
                    )
                  },
                  priceFormat: control.context.t(
                    privateTransferMessages.info.priceFromFormat
                  ),
                  price: getLowestPrice(
                    PRODUCT_IDS.PrivateTransferDepartureServioMedium
                  ),
                  isClosed: false,
                  inCart:
                    control.screen.state?.selectedDeparture ===
                    PRODUCT_IDS.PrivateTransferDepartureServioMedium,
                  inCartLabel: control =>
                    control.context.t(globalBookingMessages.labels.selected),
                },
              })}
            />
            <FieldRenderer
              control={control}
              item={buildCardButtonField({
                props: {
                  title: control =>
                    control.context.t(
                      privateTransferMessages.info.departureServioLarge
                    ),
                  ctaLabel: control =>
                    control.context.t(globalBookingMessages.buttons.select),
                  description: control =>
                    control.context.t(
                      privateTransferMessages.info.departureServioDescription,
                      {
                        guests: 2,
                        property,
                      }
                    ),
                  onClick: () => {
                    onDepartureClick(
                      PRODUCT_IDS.PrivateTransferDepartureServioLarge
                    )
                  },
                  priceFormat: control.context.t(
                    privateTransferMessages.info.priceFromFormat
                  ),
                  price: getLowestPrice(
                    PRODUCT_IDS.PrivateTransferDepartureServioLarge
                  ),
                  isClosed: false,
                  inCart:
                    control.screen.state?.selectedDeparture ===
                    PRODUCT_IDS.PrivateTransferDepartureServioLarge,
                  inCartLabel: control =>
                    control.context.t(globalBookingMessages.labels.selected),
                },
              })}
            />
          </Cards>
        </Departure>
      </Container>
    )
  }
