import { defineMessages } from 'react-intl'

const info = defineMessages({
  bookingToCloseTitle: {
    id: 'sharedFlows:contactForm.bookingToCloseTitle',
    defaultMessage: 'Not enough notice',
    description:
      'Booking Details: Header shown on the Upgrade Form when there is not enough notice to request upgrade',
  },
  bookingToCloseSubtitle: {
    id: 'sharedFlows:contactForm.bookingToCloseSubtitle',
    defaultMessage:
      'Your arrival time is closely approaching. We cannot process your request on such short notice. If your request is urgent, you can call our service desk at {phoneNo}',
    description:
      'Booking Details: Subtitle shown on the Upgrade Form when there is not enough notice to request upgrade',
  },
  bookingToCloseHotelSubtitle: {
    id: 'sharedFlows:contactForm.bookingToCloseHotelSubtitle',
    defaultMessage:
      'We cannot process your request on such short notice. If your request is urgent, you can call our service desk at {phoneNo}',
    description:
      'Booking Details: Hotel booking subtitle shown on the Upgrade Form when there is not enough notice to request upgrade',
  },
})

export const contactFormMessages = {
  info,
}
